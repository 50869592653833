import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebaseConfig';
import { collection, query, getDocs } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
const Dashboard = () => {
  const { user, logout } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sucess, setsucess] = useState(false);
  const [getdata, setGetData] = useState(true);
  const [error, setError] = useState('');
  var css = `.table-container {
    width: 100%; /* Adjust based on your requirement */
    height: 400px; /* Adjust based on your requirement */
    overflow: scroll; /* Enables scrolling */
    position: relative; /* Establishes a positioning context */
}

table {
    width: 100%;
    border-collapse: collapse; /* Optional: for styling */
}

thead th {
    position: sticky;
    top: 0; /* Stick to the top of the table */
    background-color: #fff; /* Ensures the header is not transparent */
    z-index: 10; /* Keeps the header above other content */
}
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
tbody td, thead th {
    padding: 10px;
    border: 1px solid #ccc;
}

/* Optional: Styles to improve the appearance */
table {
    min-width: 600px; /* Ensures table is wider than the container */
}

th, td {
    min-width: 120px; /* Set minimum width for columns */
}
`
  
useEffect(() => {
  setTimeout(() => {
    setSelectedDate(`${new Date().getFullYear()}-${String("0"+(new Date().getMonth()+1)).slice(-2)}-${new Date().getDate()-1}`);  
    document.getElementById("dateInput").value = `${new Date().getFullYear()}-${String("0"+(new Date().getMonth()+1)).slice(-2)}-${new Date().getDate()-1}`
  },1000)
},[])
  useEffect(() => {
    const fetchUserData = async () => {
        const userRef = doc(db, 'users', user.phoneNumber);
        try {
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserData(userSnap.data());
          } else {
            setError('No user data found.');
          }
        } catch (err) {
          setError('Failed to fetch user data.');
        }
        setLoading(false);
      }

    if(user){
      fetchUserData();
    }
    else{
      setLoading(false);
      setError('No user data found.');
      setTimeout(() => {
        logout();
      }, 3000);
    }
  }, []);
  const [selectedDate, setSelectedDate] = useState('');
  const [recordss, setrecordss] = useState([]);

  useEffect(() => {
      if (selectedDate !== '') {
          if(getdata){
            const fetchData = async () => {
              var date = new Date(selectedDate);
                const recordssRef = collection(db, `users/${userData.phoneNumber}/records/${date.getDate()}-${date.getMonth()}-${date.getFullYear()}/data`);
                const q = query(recordssRef);
                
                try {
                    const querySnapshot = await getDocs(q);
                    const fetchedrecordss = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        vehname: doc.data().vehname,
                        odometer: doc.data().odometer,
                        fuelinamount: doc.data().fuelinamount,
                        dailydistance: doc.data().dailydistance,
                        lastUpdate: doc.data().lastUpdate.toDate().toString().replace(" GMT+0530 (India Standard Time)","")
                    }));
                    if(fetchedrecordss.length=== 0){
                      setError('No data found');
                      setsucess("")
                    }else{
                      setError('');
                      setsucess('Data fetched successfully');
                    }
                    setrecordss(fetchedrecordss);
                    setGetData(false);
                } catch (error) {
                    console.error("Error fetching recordss: ", error);
                }
            };
            fetchData();
          }
      }
  }, [selectedDate]);
  useEffect(() => {
    if(error){
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  },[error])
  useEffect(() => {
    if(sucess){
      setTimeout(() => {
        setsucess('');
      }, 3000);
    }
  },[sucess])
  const handleDateChange = (records) => {
      setGetData(true);
      setrecordss([]);
      setSelectedDate(records.target.value);
  };
  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      {error && <div className="alert alert-danger">{error}</div>}
      {sucess && <div className="alert alert-success">{sucess}</div>}
      <div>
        <style>{css}</style>
           <div className="d-flex">
           <label htmlFor="dateInput" className='form-label' style={{ marginTop: '10px',fontWeight: 'bold'}}>Choose a date:</label>
            <input
                type="date"
                id="dateInput"
                className="form-control" 
                value={selectedDate}
                onChange={handleDateChange}
                style={{ marginLeft: '10px',width: "200px"}}
            />
           </div>
            <br />
            <br />
            <div className="table-container">
            {recordss.length > 0 && (
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Vehicle Number</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Date</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Running Kms (as per GPS)</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Running Kms (as per odometer)</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Difference KMS</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Fuel (Ltrs)</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Fuel (Amount)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recordss.map((records) => (
                            <tr key={records.id}>
                                <td style={{ textAlign: 'center' }}>{records.vehname}</td>
                                <td style={{ textAlign: 'center' }}>{records.lastUpdate}</td>
                                <td style={{ textAlign: 'center' }}>{records.dailydistance}</td>
                                <td style={{ textAlign: 'center' }}>{records.odometer}</td>
                                <td style={{ textAlign: 'center' }}>{records.dailydistance - records.odometer}</td>
                                <td style={{ textAlign: 'center' }}>{records.fuelinltr}</td>
                                <td style={{ textAlign: 'center' }}>{records.fuelinamount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
      </div>
      <br />
    </div>
  );
};

export default Dashboard;