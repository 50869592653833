import React, { useState } from "react";
import Home from "./Home";
import Report from "./Reports";
import { useAuth } from "../context/AuthContext";
function Dashboard() {
  const [content, setContent] = useState(<Home/>);
  const {logout}  = useAuth();
  return (
    <div>
      <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => setContent(<Report/>)}>Reports</button>
      <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => setContent(<Home/>)}>Home</button>
      <button onClick={logout} className="btn btn-danger">Logout</button>
      <h1>Dashboard</h1>
      {content}
    </div>
  );
}
export default Dashboard